<template>
  <div class="c-briik-icon">
    <component v-if="isHeroIcon" :is="name" :class="[sizeClasses, colorClasses]" />
    <Icon v-else-if="isIconJs" :name="(name as string)" :class="[sizeClasses, colorClasses]" />
  </div>
</template>

<script setup lang="ts">
import type { FunctionalComponent } from 'vue';

const { name, w, colorClass } = defineProps<{
  name: string | FunctionalComponent;
  w: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 14 | 16 | 20 | 24 | 32 | 40 | 48 | 56 | 64;
  colorClass?: string;
}>();

const isIconJs = computed(() => {
  return typeof name === 'string' && name.includes(':');
});

const isHeroIcon = computed(() => {
  return !isIconJs.value && typeof name === 'function';
});

const sizeClasses = computed(() => {
  return `w-${w} h-${w}`;
});

const colorClasses = computed(() => {
  return colorClass || 'text-skin-heading';
});
</script>

<style scoped></style>
